.title.MuiTypography-root {
  font-weight: 500;
  padding-top: 30px;
  margin-left: 15px;
  font-size: 18px;
}
.content.MuiDialogContent-root {
  margin-left: 11px;
}
.labels {
  margin-left: 5px;
  padding-bottom: 10px;
  padding-top: 20px;
  font-size: 17px;
}
.inputs {
  width: 540px;
  height: 47px;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding-left: 10px;
}
.actions.MuiDialogActions-root {
  padding-bottom: 30px;
  margin-right: 20px;
}
.cancel.MuiButtonBase-root {
  width: 115px;
  height: 37px;
  border-radius: 5px;
  background: #e9e9e9;
  color: black;
}
.submit.MuiButtonBase-root {
  width: 115px;
  height: 37px;
  border-radius: 5px;
  background: #0049ff;
  color: white;
}
.submit.MuiButtonBase-root:hover {
  width: 115px;
  height: 37px;
  border-radius: 5px;
  background: #0049ff;
  color: white;
}
.forms.MuiPaper-root {
  border-radius: 20px !important;
  border: none !important;
}
.error {
  color: red;
  padding-left: 5px;
}
.error-border {
  border: 1px solid red;
}
