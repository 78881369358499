.add {
  display: flex;
  justify-content: right;
}

.titles.MuiTypography-root {
  font-weight: 500px;
  padding-top: 30px;
  margin-left: 19px;
  font-size: 18px;
}
.content.MuiDialogContent-root {
  margin-left: 11px;
}
.label-input {
  margin-left: 12px;
  padding-bottom: 10px;
  padding-top: 15px;
}
.label-inputs {
  width: 450px;
  height: 47px;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding-left: 10px;
  margin-left: 9px;
}
.device-field {
  margin-left: 15px;
  width: 530px;
  height: 47px;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding-left: 10px;
}
.actions.MuiDialogActions-root {
  padding-bottom: 30px;
  margin-right: 20px;
}
.cancel.MuiButtonBase-root {
  width: 115px;
  height: 37px;
  border-radius: 5px;
  background: #e9e9e9;
  color: black;
}
.cancel.MuiButtonBase-root:hover {
  background: #e9e9e9;
}
.submit.MuiButtonBase-root {
  width: 115px;
  height: 37px;
  border-radius: 5px;
  background: #0049ff;
  color: white;
}
.submit.MuiButtonBase-root {
  background: #0049ff;
}

.group-form-container {
  height: 100%;
}

.group-form {
  width: 65%;
  height: 100%;
}

.selector-table-wrapper{
  height: 315px;
  overflow: auto; 
}

.selected-list-wrapper{
  height: 420px;
  overflow: auto;
  scrollbar-gutter: stable;
}

.search-heading{
  display: flex;
  align-items: center;
  justify-content: space-between;
}