.policy-title.MuiTypography-root {
  font-weight: 500;
  padding-top: 30px;
  margin-left: 32px;
  font-size: 18px;
}
.policy-content.MuiDialogContent-root {
  margin-bottom: 22px;
  margin-left: 24px;
}
.fields {
  width: 452px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #00000033;
  padding-left: 10px;
  margin-left: 8px;
}

.policy-cancel.MuiButtonBase-root {
  width: 115px;
  height: 37px;
  border-radius: 5px;
  background: #e9e9e9;
  color: black;
}
.policy-add.MuiButtonBase-root {
  width: 115px;
  height: 37px;
  border-radius: 5px;
  background: #0049ff;
  color: white;
}
.policy-add.MuiButtonBase-root:hover {
  background-color: #0049ff;
}
.policy-cancel.MuiButtonBase-root:hover {
  width: 115px;
  height: 37px;
  border-radius: 5px;
  background: #e9e9e9;
  color: black;
}
.policy-actions.MuiDialogActions-root {
  padding-bottom: 30px;
  margin-right: 55px;
}
.policy-list {
  display: flex;
  justify-content: right;
}
