.blockcategories {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.searchcategories {
  margin-right: 25px;
  width: 222px;
  height: 37px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #ddd;
  padding-left: 15px;
}

.delete-apps {
  height: 40px;
  background-color: white;
  color: black;
  border: none;
  margin-right: 10px;
}
.add-site {
  height: 40px;
  margin-right: 10px;
}
.category-dialogue-title.MuiTypography-root {
  font-size: 19px;
  margin-bottom: 15px;
}
.categories-warning {
  color: red;
  margin-top: 5px;
  margin-left: 5px;
}
.category-link {
  cursor: pointer;
}
.delete-apps:hover {
  background-color: white;
  color: black;
  border: none;
}
.error {
  color: red;
  padding-left: 5px;
}
.error-border {
  border: 1px solid red;
}
.hide {
  display: none;
}
.delete-info {
    color: gray;
    font-size: small;
}

.form-width {
  width: 60% !important;
}