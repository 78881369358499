.submit {
  display: flex;
  margin-top: 16px;
}
.add-groups.MuiButtonBase-root {
  height: 40px;
  color: white;
  background-color: #0d6efd;
}
.add-groups.MuiButtonBase-root:hover {
  height: 35px;
  color: white;
  background-color: #0d6efdc9;
}
.groups {
  padding-top: 5px;
  margin: 0px 10px;
}
.group-selection {
  margin-left: 20px;
  width: 300px;
}
.user-group {
  display: flex;
}
.user-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title.MuiTypography-root {
  font-weight: 500;
  padding-top: 30px;
  margin-left: 15px;
  font-size: 18px;
}
.content.MuiPaper-root {
  margin-left: 11px;
}
.device-cancel.MuiButtonBase-root {
  width: 115px;
  height: 37px;
  border-radius: 5px;
  background: #e9e9e9;
  color: black;
}
.device-cancel.MuiButtonBase-root:hover {
  background: #e9e9e9;
}
.device-profile-header {
  margin-top: unset !important;
}
.device-profile-detail {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  align-self: center;
}
.device-profile-icon {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  align-self: center;
}
.device-status {
  display: flex;
  align-items: center;
}
.device-profile-detail >div {
  height: 36px;
  display: flex;
  align-items: center;
}
.device-profile-detail >div>span {
  font-weight: 700;
  width: 9rem;
  text-align: start;
}
.device-actions {
  text-align: start;
}
.device-actions-heading{
  align-content: center;
  font-weight: 700;
  font-size: "18px";
}