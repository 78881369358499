.shimmering-columns-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 8px;
  margin-top: 100px;
}

.shimmering-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.shimmering-strip {
  width: 300px;
  height: 20px;
  background: linear-gradient(90deg, #eee 25%, #fafafa 50%, #eee 75%);
  animation: 1.9s linear;
  margin-bottom: 30px;
  border-radius: 50px;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%) translateY(-50%);
  }
  100% {
    transform: translateX(100%) translateY(150%);
  }
}
