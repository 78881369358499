.org-domain {
  text-align: start;
}

.list legend {
  width: 0;
}

.list {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
}

.summary-text {
  display: flex;
  flex-direction: column;
}

.summary-head {
  display: flex;
  align-items: center;
}

.margin-head {
  margin-top: 30px;
  margin-bottom: 30px;
}

.checkbox-field {
  display: flex;
  align-items: center;
}