.title.MuiTypography-root {
  font-weight: 500px;
  padding-top: 30px;
  margin-left: 15px;
  font-size: 16px;
}
.content.MuiDialogContent-root {
  margin-left: 11px;
}
.rule-label {
  margin-left: 5px;
  padding-bottom: 10px;
  padding-top: 20px;
}
.inputs {
  width: 540px;
  height: 47px;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding-left: 10px;
}
.criteriatype {
  margin-left: 5px;
  padding-bottom: 10px;
}
.labels {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.criteria-type {
  width: 200px;
  margin-right: 7px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding-left: 10px;
  color: grey;
}
.criteria-value {
  width: 400px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding-left: 10px;
}
.dialogaction.MuiDialogActions-root {
  padding-bottom: 30px;
  margin-right: 22px;
}
.cancel {
  width: 115px;
  height: 37px;
  border-radius: 5px;
  background: #e9e9e9;
  color: black;
}
.rule-title.MuiTypography-root {
  font-weight: 500;
  padding-top: 30px;
  margin-left: 15px;
  font-size: 16px;
}
.rule-input {
  width: 540px;
  height: 47px;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding-left: 10px;
}
.criteria-list {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
}

.rule-cancel.MuiButtonBase-root {
  width: 115px;
  height: 37px;
  border-radius: 5px;
  background: #e9e9e9;
  color: black;
}
.rule-submit.MuiButtonBase-root {
  width: 115px;
  height: 37px;
  border-radius: 5px;
  background: #0049ff;
  color: white;
}
.rule-cancel.MuiButtonBase-root:hover {
  width: 115px;
  height: 37px;
  border-radius: 5px;
  background: #e9e9e9;
  color: black;
}
.rule-submit.MuiButtonBase-root:hover {
  width: 115px;
  height: 37px;
  border-radius: 5px;
  background: #0049ff;
  color: white;
}
