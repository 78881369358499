.update {
  margin-top: 20px;
  background-color: rgb(0, 123, 255);
  color: white;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
}
.info {
  text-align: left;
  margin-right: 50px;
  color: #777;
  font-weight: normal;
  font-size: 13px;
}
.input {
  width: 120px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0.8px solid #bbb;
  background: #eff2ff;
  padding-left: 10px;
}
.checkboxes {
  text-align: left;
  font-weight: normal;
  padding-top: 15px;
  color: #666;
}
.textfield {
  text-align: left;
  margin-right: 130px;
}
.textfields {
  text-align: left;
  margin-right: 127px;
}
.repeats {
  text-align: left;
  margin-right: 56px;
}
