.summary-card {
  display: flex;
  align-items: center;
  padding: 1.125rem 1.3125rem;
  border-radius: 0.5rem;
  background: #fff;
  border: 0.05rem solid #e8e8e8;
  cursor: pointer;
  box-sizing: border-box;
  flex: 1 1 calc(25% - 1rem);
  min-width: 250px;
  justify-content: space-between;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .13), 0 1px 1px 0 rgba(0, 0, 0, .11);
}

.summary-card .left-section {
  text-align: left;
  flex: 1;
}

.summary-card:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 2px 4px -1px, rgba(0, 0, 0, 0.12) 0px 1px 7px 2px;
}

.MuiTypography-root.heading {
  color: black;
  font-family: "DM Sans";
  width: 170px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.count {
  color: #303030;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.summary {
  gap: 55px;
  border-radius: 8px;
  background: #cccccc38;
  height: 88px;
  width: 282px;
}
@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.summary-card.shimmer {
  position: relative;
  overflow: hidden;
}

.summary-card.shimmer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 10%,
    #f0f0f0 50%,
    transparent 90%
  );
  animation: shimmer 1s infinite linear;
}
.dlp-incidents {
  text-align: left;
  height: 10px;
  background-color: #79797938;
  margin-bottom: 20px;
}
.policy-breached {
  background-color: #79797938;
  text-align: left;
  height: 10px;
  margin-top: 25px;
  width: 40px;
}
.rules-breached {
  background-color: #79797938;
  text-align: left;
  height: 50px;
  width: 50px;
  border-radius: 10px;
}
.summary-cards {
  display: flex;
  justify-content: space-between;
}
