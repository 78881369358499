.submenu {
  width: 100%;
  text-decoration: none;
  color: black;
  padding: 0px;
}
.subitems.MuiListItemButton-root.MuiButtonBase-root {
  padding: 0;
  font-size: 14px;
  margin-left: 10px;
}
.subitems.MuiListItemButton-root.MuiButtonBase-root:hover {
  padding: 0;
  font-size: 14px;
  border-radius: 10px;
  color: black;
  margin-right: 5px;
  margin-right: 20px;
}
.subitems.MuiListItemButton-root.MuiButtonBase-root.Mui-selected {
  background-color: rgb(224 242 255) !important;
  border-radius: 10px;
  margin-right: 20px;
}
.listitems.MuiButtonBase-root.MuiListItemButton-root {
  padding: 0;
  width: 230px;
}
.listitems.MuiButtonBase-root.MuiListItemButton-root.Mui-selected {
  color: #3858e9;
  background-color: white;
}
.expand-more.MuiSvgIcon-root {
  font-size: 12px;
}
.expand-less.MuiSvgIcon-fontSizeMedium {
  font-size: 20px;
}
.nav-items.MuiList-root {
  bottom: 7px;
}
.nav-drawer.MuiBox-root {
  overflow: auto;
  padding: 0px;
  margin-top: 15px;
  scrollbar-gutter: stable;
}
.subitem {
  display: flex;
  align-items: center;
}
.subitem-icon {
  padding-left: 18px;
}
.subitem-name {
  margin-left: 10px;
}
.headers {
  min-height: 55px;
}
.header-box.MuiBox-root {
  height: 100vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}
.headers-outlet {
  background-color: #ffffff;
  padding: 30px 0px 0px 0px;
  min-height: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.headers-box {
  padding: 0px 30px 0px 30px;
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-gutter: stable;
}
.outer-card {
  padding: 40px 30px;
  height: 91%;
  overflow-y: auto;
}
.navLinkItem {
  padding-bottom: 8px;
  padding-top: 10px;
  padding-left: 15px;
  display: flex;
}

.activeNavLink {
  color: #3858e9;
}
.nav-link {
  padding-bottom: 5px;
  padding-top: 5px;
  display: flex;
}
.subitems-nav-link {
  padding-bottom: 5px;
  padding-top: 5px;
  display: flex;
  color: rgb(103 113 153);
  font-weight: 800;
  margin-left: 25px;
  font-size: 12px;
  letter-spacing: 0.035em;
}
.side-bar {
  display: flex;
}
.expand-icons {
  margin-left: auto;
}
.subitem-names {
  padding-right: 18px;
}
.subitems.MuiListItemButton-root.MuiButtonBase-root.Mui-disabled {
  opacity: 1 !important;
}
.submenu-list-item {
  margin-left: 25px;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
}
