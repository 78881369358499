.custom-float-right {
  margin-left: auto;
}

.custom-align-header {
  display: flex;
  align-items: center;
  width: 71%;
  margin-right: auto;
}

.custom-loader {
  display: flex;
  align-items: center;
  color: gray;
  font-size: small;
  font-style: italic;
}
