.content.MuiPaper-root {
  margin-left: 8px;
}
.shimmer-label {
  margin-left: 5px;
  padding-bottom: 10px;
  padding-top: 20px;
  width: 100px;
  background: #fafafa;
}
.shimmer-fields {
  width: 450px;
  height: 35px;
  background: #fafafa;
}
.shimmer-button.MuiButtonBase-root {
  width: 115px;
  height: 37px;
  border-radius: 5px;
  background: #fafafa;
}
.form-shimmer-fields {
  width: 450px;
  height: 47px;
  background: #fafafa;
}
