.radio-button-with-link {
    display: flex;
    align-items: center;
}

.form-footer {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0px 6px 5px 10px #fff;
}

.Mui-expanded.classification-accordion {
    min-height: unset !important;
}
.classification-accordion > .MuiAccordionSummary-content {
    margin: 12px 0 !important;
    font-size: 1rem;
    font-weight: 500;
}

.restriction-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select-risk-with-helper-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

.select-risk-field {
    min-width: 50%;
}