.card {
  border-radius: 8px;
  border: 0.8px solid #e8e8e8;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .13), 0 1px 1px 0 rgba(0, 0, 0, .11);
}
.card-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-heading legend {
  width: 0 !important;
}
.shimmering-chart {
  position: relative;
  overflow: hidden;
  height: 300px;
  align-items: center;
}

.shimmering-chart::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: linear-gradient(90deg, #eee 25%, #fafafa 50%, #eee 75%);
  padding-left: 40px;
  padding-right: 40px;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
