.device-info {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
}

.device-info-sign-in {
  margin-right: 28em;
  font-weight: 600;
}

.device-info-sign-out {
  font-weight: 600;
}

.device-session {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-weight: 600;
}

.session-list-item {
  border: 1px solid #1976d24f;
  margin: 5px 0;
  padding: 14px 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.session-list-username {
  margin-right: auto;
}

.session-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.session-sign-in {
  display: flex;
  align-items: center;
  color: green;
  width: 110px;
}

.session-divider {
  position: relative;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 6px;
}

.session-hours {
  background-color: #fff;
  padding: 4px 5px;
  z-index: 1;
  font-size: 12px !important;
}

.session-sign-out {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: red;
  width: 110px;
}
