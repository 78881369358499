.custom-header{
    display: flex;
}

.back-button{
    display: flex;
    cursor: pointer;
    align-items: center;
}

.icon-float-left{
    float: left;
}

.custom-radio-group{
    display: flex;
    align-items: center;
    justify-content: center;
}