.additionaltable {
  margin-bottom: 4px;
  font-size: 1em;
  padding-left: 39px;
}
.no-additional-details {
  padding-top: 1px;
  margin-bottom: 8px;
}
.additional-details {
  word-break: break-all;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-items: start;
  justify-content: start;
}

.additional-detail-heading {
  margin-bottom: 6px;
  font-weight: 700;
}

.detail-heading {
  font-weight: 600;
}

.detail-block {
  margin-right: 30px;
}