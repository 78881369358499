.MuiTableCell-root.MuiTableCell-head {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  color: #3b3b3b;
  padding: 8px;
  border-bottom: 2px solid #dfe1e6;
  background-color: #F5F5F5;
  align-items: center;
}

.MuiTableCell-root > .MuiCheckbox-root.MuiCheckbox-colorPrimary {
  padding : 5px 0;
}

.MuiTableCell-root.MuiTableCell-body {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  padding: 8px;
  color: #172b4d;
}

hr {
  border-top: 2px solid #dfe1e6;
  opacity: 1;
  margin: 0px;
}

.no-record-found-container {
  display: grid;
  justify-content: center;
}

.no-record-found-text {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}

.chips {
  display: flex;
  justify-content: flex-start;
}

.all-chips {
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-top: 10px;
  padding: 8px;
  margin-left: 8px;
  margin-right: 20px;
  max-height: 200px;
  overflow-y: auto;
}

.list-item-text {
  word-break: break-all;
}

.list-item-avtar.MuiListItemAvatar-root {
  min-width: unset;
  margin-right: 12px;
}