.summary {
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
}
.cards {
  border-radius: 8px;
  background: #fff;
  margin-top: 20px;
  padding-top: 35px;
  padding-bottom: 20px;
}
.chart-card {
  display: flex;
  gap: 30px;
}
.highest-risk {
  display: flex;
  gap: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 30px;
  margin-right: 30px;
}
.customTabs.nav-tabs {
  border-width: 0;
  --bs-nav-tabs-border-width: 0px;
  color: #a1a1a1;
}
.nav-tabs .nav-link.active {
  color: #3858e9;
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
  border-bottom: 2px solid #3858e9;
}
.nav-tabs .nav-link {
  margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
  background: 0 0;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  color: #a1a1a1;
}
.summary-cards {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.chart-card {
  display: flex;
  gap: 20px;
  border-radius: 8px;
  background-color: #fff;
  margin-top: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.dlp-incident {
  flex: 1 0 50%;
  min-width: 200px;
}
.policies-breached {
  flex: 1 0 30%;
  min-width: 200px;
}
